<template>
    <div>
        
    </div>
</template>

<script>
import { useStore } from 'vuex'

export default {
    name: 'Servicios',
    mounted(){
        const store = new useStore();
        store.commit('showContentHeader', 'Servicios')
    },
    data(){
        return{
            rootPath: process.env.BASE_URL
        }
    }    
}
</script>